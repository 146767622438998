<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人力法宝</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">在线咨询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="咨询内容" class="searchboxItem ci-full">
              <span class="itemLabel">咨询内容:</span>
              <el-input
                  v-model="searchForm.content"
                  type="text"
                  size="small"
                  placeholder="请输入问题描述"
                  clearable
              />
            </div>
            <div title="所在地区" class="searchboxItem ci-full">
              <span class="itemLabel">所在地区:</span>
              <el-cascader
                  v-model="searchForm.areaId"
                  :options="areaList"
                  :props="propsArea"
                  clearable
                  filterable
                  size="small"
              ></el-cascader>
            </div>
            <div title="咨询状态" class="searchboxItem ci-full">
              <span class="itemLabel">咨询状态:</span>
              <el-select
                  v-model="searchForm.consultState"
                  placeholder="请选择"
                  size="small"
                  clearable
              >
                <el-option
                    v-for="item in consultStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
                class="bgc-bv"
                style="margin:0 10px"
                round
                @click="getData()"
            >查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="60px"
                  fixed="left"
                  :index="indexMethod"
              />
              <el-table-column
                  label="咨询内容"
                  align="left"
                  prop="content"
                  show-overflow-tooltip
                  min-width="200px"
              />
              <el-table-column
                  label="状态"
                  align="center"
                  prop="consultState"
                  width="100px"
              >
                <template slot-scope="{row}">
                  {{ $setDictionary("DT_CONSULT_STATE", row.consultState) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="提交时间"
                  align="center"
                  prop="submitTime"
                  width="150px"
              />
              <el-table-column
                  label="操作人"
                  align="center"
                  width="90px"
                  prop="operatorName"
              />
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="{ row }" class="flexcc">
<!--                  <el-badge is-dot v-if="'10' == row.consultState">-->
<!--                    <el-button size="small" type="text" @click="editWorkOrder(row)" >编辑</el-button>-->
<!--                  </el-badge>-->
<!--                  <el-button size="small" type="text" v-else @click="editWorkOrder(row)">编辑</el-button>-->
                  <el-button size="small" type="text" @click="editWorkOrder(row)">编辑</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
    <el-dialog title="在线咨询" width="1016px" :visible.sync="editRecord" :before-close="closeWorkOrder">
      <div class="container-css">
        <div class="left">
          <div class="item-css">
            <div class="headline">咨询编号</div>
            <div class="content">{{formInfo.consultNumber}}</div>
          </div>
          <div class="item-css">
            <div class="headline">所在区域</div>
            <div class="content">{{formInfo.areaName}}</div>
          </div>
<!--          <div class="item-css">-->
<!--            <div class="headline">联系电话</div>-->
<!--            <div class="content">{{formInfo.mobile}}</div>-->
<!--          </div>-->
          <div class="item-css">
            <div class="headline">提交时间</div>
            <div class="content">{{formInfo.submitTime}}</div>
          </div>
          <div class="item-content">
            <div class="title">问题描述</div>
            <div class="content">{{formInfo.content}}</div>
            <div class="img-list">
              <img class="img-css" :src="item" v-for="item in currFileList" @click="showImage(item)" alt="" />
            </div>
          </div>
          <div class="item-css">
            <div class="headline">操作</div>
            <div class="state-css">
              <el-button type="success" round size="mini" style="color: snow;background: #24B69B"
                         :disabled="'30' == formInfo.consultState"
                         @click="finishService" >结束咨询</el-button>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title-field" @click="getChatRecords" >
            沟通记录
            <i class="el-icon-refresh" ></i>
          </div>
          <div ref="chatRecord" class="message-field">
            <template v-for="item in chatRecords">
              <div class="user-message" :key="item.chattingId" v-if="'10' === item.senderType">
                <div class="message-css" >
                  <div class="head">
                    <img class="head-css" src="@/assets/manpower/consult/user_head.png" alt="" />
                  </div>
                  <div class="message" >
                    <div class="name-css" >{{ item.nickname }}</div>
                    <div class="msg-css" v-if="'10' == item.chattingType" >{{ item.content }}</div>
                    <div class="msg-css" v-if="'20' == item.chattingType" >
                      <img style="width: 200px; cursor: pointer;"
                           :src="item.content" @click="showImage(item.content)" />
                    </div>
                  </div>
                </div>
                <div class="time-css" >{{ item.createTime }}</div>
              </div>
              <div class="my-message" :key="item.chattingId" v-if="'20' === item.senderType" >
                <div class="message-css" >
                  <div class="head">
                    <img class="head-css" src="@/assets/manpower/consult/head.png" alt="" />
                  </div>
                  <div class="message" >
                    <div class="name-css" >{{ item.nickname }}</div>
                    <div class="msg-css" >{{ item.content }}</div>
                  </div>
                </div>
                <div class="time-css" >{{ item.createTime }}</div>
              </div>
              <div class="sys-message" :key="item.chattingId" v-if="'30' === item.senderType" >
                <div class="message-css">{{ item.content }}</div>
              </div>
            </template>
          </div>
          <div class="input-field">
            <el-input ref="chat" class="input-css" type="textarea" rows="3"
                      v-model="chatContent"
                      :disabled="'30' == formInfo.consultState"
                      @keyup.enter.native="sendMessage"
                      placeholder="请输入聊天内容"   />
            <div class="send-css">
              <span class="button-css" @click="sendMessage">发送</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="查看图片" width="500px" :visible.sync="showImageState" :before-close="closeImage">
      <img style="width: 460px" :src="currImageUrl" />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "manpower_consult_consultList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      /* 搜索条件 */
      searchForm: {
        areaId: '',     //省份(区划id)
        content: '',    //问题描述
        consultState: '',      //处理状态
      },
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      consultStateList: [],  //咨询状态可选列表
      areaList: [],            //行政区划
      editRecord: false,
      formInfo: {
        consultId: "",       //在线咨询id
        consultNumber: "",   //咨询编号
        // areaId: "",          //行政区划
        areaName: "",        //行政区划名称
        submitTime: "",      //提交时间
        consultState: "",    //咨询状态
        content: "",         //咨询内容

      },
      currFileList: [],        //当前文件列表（图片访问地址集合）
      previousConsultState: '', //之前的咨询状态
      chatRecords: [{     //聊天记录
        chattingId: "",   //聊天id
        senderType: "",   //发言方 [10-用户；20-律师]
        chattingType: "", //消息类型 [10-文本; 20-图片]
        nickname: "",     //昵称
        content: "",      //内容
        createTime: "",   //时间
      }],
      chatContent: "",    //消息内容
      showImageState: false,   //查看照片
      currImageUrl: "",   //照片地址
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getDictionary();
    this.getAreaTree();
  },
   methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      }
      if (this.searchForm.content) {
        params.content = this.searchForm.content;
      }
      if (this.searchForm.areaId) {
        params.areaId = this.searchForm.areaId;
      }
      if (this.searchForm.consultState) {
        params.consultState = this.searchForm.consultState;
      }
      this.doFetch({
        url: "/biz/consult/mgt/page",
        params,
        pageNum,
      }, true, 9);
    },
    // 获取字典
    getDictionary() {
      // 工单状态
      let arr1 = this.$setDictionary("DT_CONSULT_STATE", "list");
      for (const key in arr1) {
        this.consultStateList.push({
          value: key,
          label: arr1[key],
        });
      }
    },
    /* 行政区划 */
    getAreaTree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areaList = ret.data;
      });
    },
    //编辑工单记录
    editWorkOrder(row) {
       //编辑框左侧内容赋值 (浅拷贝即可)
       this.formInfo = {... row};
       this.getFileList(row.consultId);
       //调用接口获取聊天消息
       this.getChatRecords();
       this.editRecord = true;
     },
     //获取当前咨询图片集合
     getFileList(consultId) {
       this.$post("/biz/consult/mgt/file/list", {"consultId": consultId}, 3000, true, 9)
           .then((ret) => {
             this.currFileList = ret.data;
           });
     },
     //获取聊天记录
     getChatRecords() {
       this.$post(
           "/biz/consult/chatting/mgt/list",
           {"consultId": this.formInfo.consultId},
           3000, true, 9)
           .then((ret) => {
             this.chatRecords = ret.data || [];
             this.scrollBottom();
           }).catch(() => {
             this.$message({
               message: "获取聊天消息异常",
               type: "error",
               duration: 2000,
             });
           });
     },
     //发送聊天消息
     sendMessage() {
       if (!this.chatContent) {
         this.$message({
           message: "请输入聊天消息内容",
           type: "error",
           duration: 2000,
         });
         this.$refs.chat.focus();
         return;
       }
       this.$post("/biz/consult/chatting/mgt/answer",
           {
             "consultId": this.formInfo.consultId,
             "chattingType": "10",
             "content": this.chatContent,
           },
           3000, true, 9)
           .then(() => {
             //将聊天消息添加到聊天框
             this.chatRecords.push({
               senderType: "20",
               nickname: '我',
               content: this.chatContent,
               createTime: "刚刚",   //时间
             });
             this.chatContent = "";
             this.scrollBottom();
             this.$refs.chat.focus();
           }).catch(() => {
             this.$message({
               message: "回复聊天消息发生异常，请联系管理员",
               type: "error",
               duration: 2000,
             });
           });
     },
     //结束咨询
     finishService() {
        this.$confirm('您正在结束当前咨询,请确认是否结束')
            .then(() => {
              this.$post("/biz/consult/chatting/mgt/finish", {"consultId": this.formInfo.consultId},
                  3000, true, 9)
                  .then((ret) => {
                    this.$message({
                      message: "已经结束咨询",
                      type: "success",
                      duration: 2000
                    })
                  }).catch(() => {
                    this.$message({
                      message: "操作失败",
                      type: "error",
                      duration: 2000
                    })
                  });
            });
     },
     //通过延迟回调,在dom更新结束后,若消息超出div上限，滚动条滚动至底部
     scrollBottom() {
       this.$nextTick(() => {
         const chatMsg = this.$refs.chatRecord;
         if (chatMsg.scrollHeight > 512) {
           chatMsg.scrollTop = chatMsg.scrollHeight;
         }
       });
     },
     //关闭工单(清空表单数据)
     closeWorkOrder() {
       this.editRecord = false;
       this.formInfo = {
         consultId: "",       //在线咨询id
         consultNumber: "",   //咨询编号
         areaName: "",        //行政区划名称
         submitTime: "",      //提交时间
         consultState: "",    //咨询状态
         content: "",         //咨询内容
       };
       this.currFileList = [];
       this.getData(this.pageNum);
     },
     //
     showImage(val) {
      this.currImageUrl = val;
      this.showImageState = true;
     },
     //关闭图片查看
     closeImage() {
      this.showImageState = false;
      this.currImageUrl = "";
     }
  },
}
</script>
<style lang="less" scoped>
.container-css {
  display:inline-flex;
  justify-content: flex-start;

  .left {
    width: 300px;
    margin-right: 20px;

    .item-css {
      width: 300px;
      height: 30px;
      padding-bottom: 16px;
      border-bottom: 1px solid #EEEEEE;
      margin-bottom: 16px;

      display:inline-flex;
      flex-direction: row;
      justify-content: space-between;
      .headline {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .content {
        font-weight: 400;
        line-height: 14px;
        text-align: right;
        font-style: normal;
        text-transform: none;
      }
      .state-css {
        display:inline-flex;
        flex-direction: column;
        justify-content: center;
        .select-css {
          width: 110px;
        }
      }
    }

    .item-content {
      padding-bottom: 16px;
      //border-bottom: 1px solid #EEEEEE;
      margin-bottom: 16px;
      .title {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        padding-bottom: 12px;
      }
      .content {
        height: 320px;
        overflow-x: hidden;
        overflow-y: auto;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        //text-indent: 2em;
      }
      .img-list {
        width: 100%;
        overflow-x: auto; /* 允许水平滚动 */
        white-space: nowrap; /* 防止内容换行 */

        display:inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        .img-css {
          flex-shrink: 0;
          padding: 5px;
          width: 50px;
          height: 50px;
          cursor: pointer;
        }
      }
    }
  }
  .right {
    width: 645px;
    .title-field {
      height: 14px;
      font-weight: 600;
      font-size: 14px;
      color: #666666;
      line-height: 14px;
      text-align: right;
      font-style: normal;
      text-transform: none;
      margin-bottom: 16px;
      cursor: pointer;
    }
    .message-field {
      width: 100%;
      height: 512px;
      background: #F5F7FA;
      border-radius: 2px 2px 0 0;
      padding-top: 20px;
      display: inline-flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;

      .my-message {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;

        .message-css {
          display: inline-flex;
          flex-direction: row-reverse;
          justify-content: flex-start;
          .head {
            padding-right: 20px;
            .head-css {
              width: 32px;
              height: 32px;
            }
          }
          .message {
            max-width: 550px;
            padding-right: 8px;
            display: inline-flex;
            flex-direction: column;
            .name-css {
              padding-bottom: 4px;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 12px;
              text-align: right;
              font-style: normal;
              text-transform: none;
            }
            .msg-css {
              padding: 10px;
              background: #24B69B;
              border-radius: 8px 8px 8px 8px;
              font-weight: 400;
              font-size: 14px;
              color: #FFFFFF;
              line-height: 20px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }
        .time-css {
          width: 100%;
          padding: 20px 0;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          line-height: 10px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }

      .user-message {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;

        .message-css {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-start;
          .head {
            padding-left: 20px;
            .head-css {
              width: 32px;
              height: 32px;
            }
          }
          .message {
            max-width: 550px;
            padding-left: 8px;
            display: inline-flex;
            flex-direction: column;
            .name-css {
              padding-bottom: 4px;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 12px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
            .msg-css {
              padding: 10px;
              background: #FFFFFF;
              border-radius: 8px 8px 8px 8px;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }
        .time-css {
          padding: 20px 0;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          line-height: 10px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }

      .sys-message {
        padding-bottom: 20px;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .message-css {
          padding: 8px;
          background: #E5EBF4;
          border-radius: 4px 4px 4px 4px;

          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 12px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }

    .input-field {
      width: 100%;
      height: 100px;
      background: #F5F7FA;
      border-radius: 0 0 2px 2px;
      border-top: 1px solid #E4E7ED;

      display:inline-flex;
      flex-direction: row;
      justify-content: space-between;
      .input-css {
        width: 90%;
        padding: 12px;

      }
      .send-css {
        min-width: 76px;
        display: inline-flex;
        flex-direction: column-reverse;
        padding: 12px;

        .button-css {
          max-width: 52px;
          background: #24B69B;
          border-radius: 4px 4px 4px 4px;
          padding: 8px 12px;
          cursor: pointer;

          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 14px;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }

      }
    }
  }
}
</style>